<template>
<!-- <OrdersTabs activeTab="accepted" /> -->
    <div class="bg-white">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Accepted Orders</h1>
                <!-- <div>A list of orders that have been accepted</div> -->
            </div>
            <div>
                <router-link class="btn btn-primary" to="/orders/create" v-if="preferences.showCreateButton">Create Order</router-link>
            </div>
        </div>
        <form class="bg-light p-3" novalidate @submit.prevent="submitFilter">
            <div class="row">
                <div class="col-md-6 col-xxl-3 mb-3">
                    <TextInput
                        label="Patient name / reference"
                        v-model="patient_name"
                        :v="v$.patient_name"
                    />
                </div>
                <div class="col-md-6 col-xxl-3 mb-3">
                    <TextInput
                        label="Swift order ID"
                        v-model="order_id"
                        :v="v$.order_id"
                    />
                </div>
                <div class="col-md-4 col-xxl-2 mb-3">
                    <DropdownInput
                        label="Status"
                        v-model="status"
                        :v="v$.status"
                        :options="statuses"
                    />
                </div>
                <div class="col-md-4 col-xxl-2 mb-3">
                    <DropdownInput
                        label="Service level"
                        v-model="standard"
                        :v="v$.standard"
                        :options="standards"
                    />
                </div>
                <div class="col-md-3 col-lg-2 mb-3">
                    <label class="form-label">&nbsp;</label>
                    <div class="d-grid">
                    <button class="btn btn-primary me-1" type="submit">Filter</button>
                    </div>
                </div>
            </div>
        </form>
        <br />
        <ul v-if="orderData && orderData.data.length" class="order-list mb-4">
            <li class="header-row">
                <div class="row align-items-center">
                    <div class="col">
                        <div class=""><strong>Patient &amp; Dentist</strong></div>
                    </div>
                    <div class="col flex-grow-0 flex-sm-grow-1 d-flex justify-content-between align-items-center">
                        <div class="d-none d-sm-block text-center order-status-title"><strong>Status</strong></div>
                        <div class="d-none d-md-block text-center">
                            <div class=""><strong>Received date</strong></div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </li>
            <li v-for="order of orderData.data" v-bind:key="order.id">
                <div class="row align-items-center">
                    <div class="col">
                        <div class=""><strong>{{ order.patient_name }}</strong></div>
                        <div class="">{{ order.dentist.first_name }} {{ order.dentist.surname }}</div>
                    </div>
                    <div class="col flex-grow-0 flex-sm-grow-1 d-flex justify-content-between align-items-center">
                        <div class="d-none d-sm-block text-center order-status"><span class="badge" :class="statusClass(order.status.id)">{{ order.status.description.startsWith('On Hold') ? 'On Hold' : order.status.description }}</span></div>
                        <div class="d-none d-md-block text-center">
                            <div class="order-date">{{ $filters.formatDate(order.received_datetime) }}</div>
                        </div>
                        <div class="">
                            <router-link class="btn btn-secondary" :to="{ name: 'Order', params: { id: order.id }}">View</router-link>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="orderData && !orderData.data.length">
            No Orders
        </div>
        <Pagination
            v-if="orderData && orderData.data.length"
            v-bind:currentPage="currentPage"
            v-bind:lastPage="lastPage"
            viewComponent="Orders"
        />
    </div>
</template>

<style scoped lang="scss">

    .order-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }

        .header-row
        {
            border-top: 0px;
        }
    }
</style>

<script>
    import api from "@/services/api";
    import useVuelidate from '@vuelidate/core'
    import { minLength } from '@vuelidate/validators'
    import Pagination from '@/components/Pagination.vue';
    // import OrdersTabs from '@/components/OrdersTabs.vue';
    import TextInput from '@/components/TextInput.vue';
    import DropdownInput from '@/components/DropdownInput.vue';

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        components: {            
            Pagination,
            // OrdersTabs,
            TextInput,
            DropdownInput,
        },
        validations () {
            return {
                order_id: { minLength: minLength(8) },
                patient_name: { },
                standard: { },
                status: { },
            }
        },
        data() {
            return {
                orderData: null,
                patient_name: '',
                order_id: '',
                standard: '',
                status: '',
                statuses: [{id:7, label:'In Planning'}, {id:2, label:'In Production'}, {id:3, label:'Dispatched'}, {id:4, label:'On Hold'}, {id:5, label:'Cancelled'}, {id:8, label:'Attention Required'}],
                standards: [{id:1, label:'Standard / NHS'}, {id:2, label:'Independent'}, {id:3, label:'Private'}],
                preferences: {
                    showCreateButton: null,
                },
            }
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.orderData !== null)
                {
                    return parseInt(this.orderData.meta.last_page);
                }

                return null;
            },
        },
        methods: {
            moveUp() {
                const html = document.querySelector('html')
                var scrollBehavior = html.style.scrollBehavior;
                html.style.scrollBehavior = 'auto'
                window.scrollTo({
                    top: 0,
                });
                setTimeout(() => {
                    html.style.scrollBehavior = scrollBehavior;
                }, 100);
            },
            fetchOrders: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.orderData = null;
                    return;
                }

                this.moveUp();

                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                    page: this.currentPage,
                };

                if(this.patient_name.trim().length)
                {
                    params.patient_name = this.patient_name;
                }

                if(this.order_id.trim().length)
                {
                    params.order_id = this.order_id;
                }

                if (this.standard.trim().length) {
                    params.standard = this.standard;
                }

                if (this.status.trim().length) {
                    params.status = this.status;
                }
                
                api
                .get('/api/v1/orders', {
                    params: params
                })
                .then((response) => {
                    this.orderData = response.data;
                })
                .catch(() => {
                    console.log('Catch orders error');
                });
            },
            fetchPracticePrefs: function () {
                api
                .get('/api/v1/practices/' + this.$store.state.currentPracticeId + '/preferences', {
                })
                .then((response) => {
                    this.preferences = {
                        showCreateButton: response.data.data.show_create_order,
                    };
                })
                .catch((reason) => {
                    console.log('Catch practice preferences error');
                    console.log(reason);
                });
            },
            submitFilter: async function()
            {
                var isValid = await this.v$.$validate();

                if(isValid)
                {
                    var query = {};

                    if(this.patient_name.trim().length)
                    {
                        query.patient_name = this.patient_name.trim();
                    }

                    if(this.order_id.trim().length)
                    {
                        query.order_id = this.order_id.trim();
                    }

                    if (this.standard.trim().length) {
                        query.standard = this.standard.trim();
                    }

                    if (this.status.trim().length) {
                        query.status = this.status.trim();
                    }

                    this.$router.replace({ name: 'Orders', query: query});
                    this.fetchOrders();
                }
                else
                {
                    console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                }

                return false;
            },
            statusClass: function(status) {
                switch(status)
                {
                    case 1: return 'bg-secondary'; 
                    case 2: return 'bg-warning'; 
                    case 3: return 'bg-success';
                    case 4: return 'bg-danger';
                    case 5: return 'bg-secondary';
                    case 7: return 'bg-warning';
                    case 8: return 'bg-danger';
                    default: return 'bg-secondary';
                }
            },
            updateFromQuery: function() {
                this.patient_name = '';
                this.order_id = '';
                this.standard = '';
                this.status = '';

                if(typeof this.$route.query.patient_name !== 'undefined')
                {
                    this.patient_name = this.$route.query.patient_name;
                }

                if(typeof this.$route.query.order_id !== 'undefined')
                {
                    this.order_id = this.$route.query.order_id;
                }

                if(typeof this.$route.query.standard !== 'undefined')
                {
                    this.standard = this.$route.query.standard;
                }

                if(typeof this.$route.query.status !== 'undefined')
                {
                    this.status = this.$route.query.status;
                }
            },
        },
        mounted () {
            this.updateFromQuery();
            this.fetchOrders();
            this.fetchPracticePrefs();
        },
        watch: {
            currentPage() {
                this.fetchOrders();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchOrders();
            },
            '$route.query': function() {
                this.updateFromQuery();
                this.fetchOrders();
            },
        },
    }

</script>
